import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _e84aad52 = () => interopDefault(import('../src/pages/cookie-policy.vue' /* webpackChunkName: "pages/cookie-policy" */))
const _426474af = () => interopDefault(import('../src/pages/maintenance.vue' /* webpackChunkName: "pages/maintenance" */))
const _565285c8 = () => interopDefault(import('../src/pages/manage-booking/index.vue' /* webpackChunkName: "pages/manage-booking/index" */))
const _4d33b9aa = () => interopDefault(import('../src/pages/privacy-policy.vue' /* webpackChunkName: "pages/privacy-policy" */))
const _2ceebb54 = () => interopDefault(import('../src/pages/receipt.vue' /* webpackChunkName: "pages/receipt" */))
const _cd5b9d88 = () => interopDefault(import('../src/pages/search.vue' /* webpackChunkName: "pages/search" */))
const _32f7e34b = () => interopDefault(import('../src/pages/search-booking/index.vue' /* webpackChunkName: "pages/search-booking/index" */))
const _aed1aa1a = () => interopDefault(import('../src/pages/select-extras/index.vue' /* webpackChunkName: "pages/select-extras/index" */))
const _c9d163f0 = () => interopDefault(import('../src/pages/terms-and-conditions.vue' /* webpackChunkName: "pages/terms-and-conditions" */))
const _be5ae0dc = () => interopDefault(import('../src/pages/travel-extras.vue' /* webpackChunkName: "pages/travel-extras" */))
const _709762ac = () => interopDefault(import('../src/pages/unsupported-browser.vue' /* webpackChunkName: "pages/unsupported-browser" */))
const _57427bf7 = () => interopDefault(import('../src/pages/manage-booking/cancel/index.vue' /* webpackChunkName: "pages/manage-booking/cancel/index" */))
const _db6b7298 = () => interopDefault(import('../src/pages/manage-booking/modify.vue' /* webpackChunkName: "pages/manage-booking/modify" */))
const _73df4cae = () => interopDefault(import('../src/pages/manage-booking/receipt.vue' /* webpackChunkName: "pages/manage-booking/receipt" */))
const _93bec190 = () => interopDefault(import('../src/pages/manage-booking/update-details.vue' /* webpackChunkName: "pages/manage-booking/update-details" */))
const _223c8c13 = () => interopDefault(import('../src/pages/select-extras/fasttrack/index.vue' /* webpackChunkName: "pages/select-extras/fasttrack/index" */))
const _6b318ddd = () => interopDefault(import('../src/pages/select-extras/lounges/index.vue' /* webpackChunkName: "pages/select-extras/lounges/index" */))
const _1527f65a = () => interopDefault(import('../src/pages/select-extras/product/index.vue' /* webpackChunkName: "pages/select-extras/product/index" */))
const _0eab19c6 = () => interopDefault(import('../src/pages/manage-booking/cancel/receipt.vue' /* webpackChunkName: "pages/manage-booking/cancel/receipt" */))
const _74cd3424 = () => interopDefault(import('../src/pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/cookie-policy",
    component: _e84aad52,
    name: "cookie-policy"
  }, {
    path: "/maintenance",
    component: _426474af,
    name: "maintenance"
  }, {
    path: "/manage-booking",
    component: _565285c8,
    name: "manage-booking"
  }, {
    path: "/privacy-policy",
    component: _4d33b9aa,
    name: "privacy-policy"
  }, {
    path: "/receipt",
    component: _2ceebb54,
    name: "receipt"
  }, {
    path: "/search",
    component: _cd5b9d88,
    name: "search"
  }, {
    path: "/search-booking",
    component: _32f7e34b,
    name: "search-booking"
  }, {
    path: "/select-extras",
    component: _aed1aa1a,
    name: "select-extras"
  }, {
    path: "/terms-and-conditions",
    component: _c9d163f0,
    name: "terms-and-conditions"
  }, {
    path: "/travel-extras",
    component: _be5ae0dc,
    name: "travel-extras"
  }, {
    path: "/unsupported-browser",
    component: _709762ac,
    name: "unsupported-browser"
  }, {
    path: "/manage-booking/cancel",
    component: _57427bf7,
    name: "manage-booking-cancel"
  }, {
    path: "/manage-booking/modify",
    component: _db6b7298,
    name: "manage-booking-modify"
  }, {
    path: "/manage-booking/receipt",
    component: _73df4cae,
    name: "manage-booking-receipt"
  }, {
    path: "/manage-booking/update-details",
    component: _93bec190,
    name: "manage-booking-update-details"
  }, {
    path: "/select-extras/fasttrack",
    component: _223c8c13,
    name: "select-extras-fasttrack"
  }, {
    path: "/select-extras/lounges",
    component: _6b318ddd,
    name: "select-extras-lounges"
  }, {
    path: "/select-extras/product",
    component: _1527f65a,
    name: "select-extras-product"
  }, {
    path: "/manage-booking/cancel/receipt",
    component: _0eab19c6,
    name: "manage-booking-cancel-receipt"
  }, {
    path: "/",
    component: _74cd3424,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
